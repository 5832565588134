<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>
            <a
              :href="'/projects/' + project.id"
              tile="Project details"
            >{{project.name}}</a>
            <div v-if="project.description" class="note" v-html="project.description"></div>
          </div>
        </header>

        <template v-if="reportData.length > 0">
          <div 
            v-for="(reportCategory, i) in reportData"
            :key="i"
            class="py-1"
          >
            <div class="stats-tile">
              <div class="tile-header">
                <h3 class="tile-title"><span>{{ reportCategory.heading }}</span> <strong>{{ findings.length }}</strong></h3>
              </div>

              <ul class="stats-list" style="max-height: 300px; overflow: auto;">
                <li v-for="(value, key) in reportCategory.data">
                  <span>{{ reportItemName(key) }}</span> <strong> {{ value }}</strong>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="!isSpinnerVisible">Selected project has no findings!</div> 
        </template>
      </section>
      
      <div class="entity-details-actions">
        <div>
          <a
            :href="'/projects/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm btn-block"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/projects/' + entityId + '/edit'"
            title="Edit"
            class="btn-expandable btn-expandable-edit btn-expandable-sm btn-block"
          >
            <span class="icon" data-icon="edit"></span>
            <span>Edit</span>
          </a>
        </div>
        <div
          v-if="userCanUpdate"
        >
          <a
            :href="'/projects/' + entityId"
            title="Remove"
            class="btn-expandable btn-expandable-delete btn-expandable-sm btn-block"
            data-confirm="Are you sure?"
            data-method="delete"
          >
            <span class="icon" data-icon="delete"></span>
            <span>Remove</span>
          </a>
        </div>
      </div>
    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import helpers from "../../../helpers.js";

import EntityDetails from "./entity_details";

export default {
  extends: EntityDetails,

  components: {
    
  },

  setup () {
    return {
      h$: helpers
    }
  },

  data() {
    return {
      project: {},
      findings: [],
      reportData: [],
      reportTemplate: {
        'Finding Types': '[findingType][name]',
        'Asset Species': '[component][physicalAsset][details][Species]'
      },
      isSpinnerVisible: true
    }
  },

  watch: {
    entityId: {
      handler(newValue, oldValue) {
        this.fetchProject();
      }, 
      immediate: true
    }
  },

  methods: {
    fetchProject: function() {
      app.graphql.get("details.project",
        {
          filter: {
            id: [this.entityId]
          }
        },
        (data) => {
          this.isLoaded = true

          let currentProject = data.data.projects.edges[0].node;

          this.project = currentProject;

          this.setReportTemplate();

          this.getReportData(this.entityId);
        },

        (error) => {
          $.toast({
            priority: "danger",
            title: "Something went wrong!",
            message: "Unable to fetch project!"
          });
        }
      );
    },

    setReportTemplate: function() {
      if (this.project.description) {
        const template = this.project.description;

        const regex = /<!--(.*?)-->/g;
        
        const matches = [...template.matchAll(regex)];

        const contents = matches.map(match => match[1]);

        if (contents.length > 0) {
          try {
            this.reportTemplate = JSON.parse(contents[0]).reportTemplate;
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    
    getReportData: function(projectId) {
      let fetchParams = {
        filter: {
          projectId: projectId
        }
      };

      this.isSpinnerVisible = true;

      this.reportData = [];

      this.findings = [];

      let currentPage = 1,
          maxPage = 200;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get('report.findings',
          fetchParams,

          (data) => {
            let findings = data.data.findings,
                pageInfo = findings.pageInfo;

            success(findings, pageInfo);
          },

          (error) => {
            $.toast({
              priority: 'danger',
              title: 'Something went wrong!',
              message: 'Unable to fetch report!'
            });
          }
        )
      };

      let getFindings = (cursor) => {
        fetch(
          cursor,

          (findings, pageInfo) => {
            findings.edges.forEach((edge) => this.findings.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getFindings(pageInfo.endCursor))
            }
            else {
              if (this.findings.length > 0) {
                this.setReport()
              }
              else {
                this.isSpinnerVisible = false;
              }
            }
          }
        )
      };

      getFindings(window.btoa(0));
    },

    setReport: function() {
      

      this.prepareReportData();
    },
    
    prepareReportData: function() {
      let tmpData = [];

      this.findings.forEach((finding) => {
        if (finding.details) {
          finding.details = JSON.parse(finding.details)
        }

        if (finding.component.details) {
          finding.component.details = JSON.parse(finding.component.details)
        }

        if (finding.component.physicalAsset.details) {
          finding.component.physicalAsset.details = JSON.parse(finding.component.physicalAsset.details)
        }

        let findingReportData = [];

        Object.keys(this.reportTemplate).forEach((r) => {
          findingReportData.push(this.h$.getValueByPath(finding, this.reportTemplate[r]))
        });

        tmpData.push(findingReportData);
      })

      for (const [index, [key, value]] of Object.entries(this.reportTemplate).entries()) {
        let reportItem = {
            heading: key,
            data: []
          };

          reportItem.data = tmpData.reduce((acc, item) => {
            acc[item[index]] = (acc[item[index]] || 0) + 1;
            return acc;
          }, {});

        this.reportData.push(reportItem)
      }

      setTimeout(() => {
        this.handlereportTiles();

        this.isSpinnerVisible = false;
      }, 500);
    },

    reportItemName: function(name) {
      return name != 'undefined' ? name : 'Not Defined';
    },

    handlereportTiles: function() {
      document.querySelectorAll('.stats-tile .stats-list').forEach(el => {
        el.classList.toggle("has-scrollbar", el.scrollHeight > el.clientHeight);
      });
    }
  }
}

</script>
