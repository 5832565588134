<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <DetailsButton
        title="Project Details"
        entity-type="projectDetails"
        :entity="id"
      >
        <a
          :href="projectUrl"
        >{{name}}</a>
        <div v-if="description" class="note" v-html="description"></div>
      </DetailsButton>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.draft > 0">
        <CellAction
          action="review"
          text="Review"
          title="Review all (In Draft) findings"
          confirm="Are you sure? Status of all Project findings will be changed to In Review!"
          :label="findingsInDraft"
          @clickAction="reviewFindings"
        />
      </template>
      <template v-else>
        {{draftFindingsCount}}
      </template>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) findings"
          confirm="Are you sure? All Project findings will be approved!"
          :label="findingsInReview"
          @clickAction="approveFindings"
        />
      </template>
      <template v-else>
        {{ reviewFindingsCount }}
      </template>
    </td>

    <td class="text-center">
      {{ approvedFindingsCount }}
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && remediationStatusCounts.draft > 0">
        <CellAction
          action="review"
          text="Review"
          title="Review all (In Draft) remeditations"
          confirm="Are you sure? Status of all Activity remediations will be changed to In Review!"
          :label="remediationsInDraft"
          @clickAction="reviewRemediations"
        />
      </template>
      <template v-else>
        {{draftRemediationsCount}}
      </template>
    </td>

    <td class="text-center">
      <template v-if="policyUpdate && remediationStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) remediations"
          confirm="Are you sure? All Activity remediations will be approved!"
          :label="remediationsInReview"
          @clickAction="approveRemediations"
        />
      </template>
      <template v-else>
        {{reviewRemediationsCount}}
      </template>
    </td>

    <td class="text-center">{{approvedRemediationsCount}}</td>

    <td class="text-right">
      {{ h$.formatDate.short(latestActivityDate) }}
    </td>

    <td class="table-row-actions">
      <RowAction
        :href="projectUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="projectUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="projectUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingsImportUrl"
        title="Import Findings"
        action="import"
      />
    </td>
  </tr>
</template>

<script>
import helpers from "../../helpers.js";

import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";
import DetailsButton from "../shared/grid/details_button";

export default {
  extends: Row,

  components: {
    CellAction,
    RowAction,
    DetailsButton
  },

  props: {
    id: String,
    name: String,
    description: String,
    latestActivityDate: String,
    clientCompany: Object,
    findingStatusCounts: Object,
    remediationStatusCounts: Object,
    index: Number
  },

  setup () {
    return {
      h$: helpers
    }
  },

  computed: {
    projectUrl: function () {
      return "/projects/" + this.id;
    },

    clientCompanyUrl: function () {
      return "/client_companies/" + this.clientCompany.id;
    },

    findingsImportUrl: function() {
      return "/finding_imports/new?project_id=" + this.id;
    },

    canToggleStatus: function () {
      return this.findingStatusCounts.review > 0 || this.findingStatusCounts.draft > 0;
    },

    findingsInReview: function() {
      return this.findingStatusCounts.review.toString();
    },

    findingsInDraft: function() {
      return this.findingStatusCounts.draft.toString();
    },

    reviewFindingsCount: function() {
      return (this.findingStatusCounts.review > 0) ? this.findingStatusCounts.review : "-";
    },

    approvedFindingsCount: function() {
      return (this.findingStatusCounts.approved > 0) ? this.findingStatusCounts.approved : "-";
    },

    draftFindingsCount: function() {
      return (this.findingStatusCounts.draft > 0) ? this.findingStatusCounts.draft : "-";
    },

    remediationsInReview: function() {
      return this.remediationStatusCounts.review.toString();
    },

    remediationsInDraft: function() {
      return this.remediationStatusCounts.draft.toString();
    },

    reviewRemediationsCount: function() {
      return (this.remediationStatusCounts.review > 0) ? this.remediationStatusCounts.review : "-";
    },

    approvedRemediationsCount: function() {
      return (this.remediationStatusCounts.approved > 0) ? this.remediationStatusCounts.approved : "-";
    },

    draftRemediationsCount: function() {
      return (this.remediationStatusCounts.draft > 0) ? this.remediationStatusCounts.draft : "-";
    }
  },

  methods: {
    reviewFindings: function () {
      app.graphql.get(
        "update.project.reviewFindings",

        {
          input: {
            projectId: this.id
          }
        },

        (data) => {
          const response = data.data.reviewProjectFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Project Findings",
              message: "All project findings from <strong>In Draft</strong> status set to <strong>In Review</strong>"
            })

            this.$emit("update-status", response.project);
          }
        }
      )
    },

    approveFindings: function () {
      app.graphql.get(
        "update.project.approveFindings",

        {
          input: {
            projectId: this.id
          }
        },

        (data) => {
          const response = data.data.approveProjectFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Project Findings",
              message: "All project findings from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.project);
          }
        }
      )
    },

    approveRemediations: function () {
      app.graphql.get(
        "update.project.approveRemediations",

        {
          input: {
            projectId: this.id
          }
        },

        (data) => {
          const response = data.data.approveProjectRemediations;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update activity",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Project Remediations",
              message: "All project remediations from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.project);
          }
        }
      )
    },

    reviewRemediations: function () {
      app.graphql.get(
        "update.project.reviewRemediations",

        {
          input: {
            projectId: this.id
          }
        },

        (data) => {
          const response = data.data.reviewProjectRemediations;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update activity",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Project Remediations",
              message: "All project remediations from <strong>In Draft</strong> status set to <strong>In Review</strong>"
            })

            this.$emit("update-status", response.project);
          }
        }
      )
    }
  }
}
</script>
