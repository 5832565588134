<template>
  <tr :class="{'is-marked': marked}">
    <td>
      <a
        :href="projectUrl"
        :title="name"
        :data-entity="id"
      >{{name}}</a>
      <div v-if="description" class="note" v-html="description"></div>
    </td>

    <td v-if="showClient">
      <a :href="clientCompanyUrl" title="Details">{{clientCompany.name}}</a>
    </td>

    <td class="text-center">{{draftFindingsCount}}</td>

    <td class="text-center">
      <template v-if="policyUpdate && findingStatusCounts.review > 0">
        <CellAction
          action="approve"
          text="Approve"
          title="Approve all (In Review) findings"
          :label="findingsInReview"
          @clickAction="approveFindings"
        />
      </template>
      <template v-else>
        {{reviewFindingsCount}}
      </template>
    </td>

    <td class="text-center">{{approvedFindingsCount}}</td>

    <td class="table-row-actions">
      <RowAction
        :href="projectUrl"
        action="read"
      />

      <RowAction
        v-if="policyUpdate"
        :href="projectUrl + '/edit'"
        action="update"
      />

      <RowAction
        v-if="policyUpdate"
        :href="projectUrl"
        action="delete"
      />

      <RowAction
        v-if="policyUpdate"
        :href="findingsImportUrl"
        title="Import Findings"
        action="import"
      />
    </td>
  </tr>
</template>

<script>
import Row from "../shared/grid/row";
import CellAction from "../shared/grid/cell_action";
import RowAction from "../shared/grid/row_action";

export default {
  extends: Row,

  components: {
    CellAction,
    RowAction
  },

  props: {
    id: String,
    name: String,
    description: String,
    clientCompany: Object,
    findingStatusCounts: Object,
    showClient: Boolean,
    index: Number
  },

  computed: {
    projectUrl: function () {
      return "/projects/" + this.id;
    },

    clientCompanyUrl: function () {
      return "/client_companies/" + this.clientCompany.id;
    },

    findingsImportUrl: function() {
      return "/finding_imports/new?project_id=" + this.id;
    },

    canToggleStatus: function () {
      return this.findingStatusCounts.review > 0 || this.findingStatusCounts.draft > 0;
    },

    findingsInReview: function() {
      return this.findingStatusCounts.review.toString();
    },

    reviewFindingsCount: function() {
      return (this.findingStatusCounts.review > 0) ? this.findingStatusCounts.review : "-";
    },

    approvedFindingsCount: function() {
      return (this.findingStatusCounts.approved > 0) ? this.findingStatusCounts.approved : "-";
    },

    draftFindingsCount: function() {
      return (this.findingStatusCounts.draft > 0) ? this.findingStatusCounts.draft : "-";
    }
  },

  methods: {
    approveFindings: function () {
      app.graphql.get(
        "update.project.approveFindings",

        {
          input: {
            projectId: this.id
          }
        },

        (data) => {
          const response = data.data.approveProjectFindings;

          if (response.errors) {
            response.errors.forEach((error) => {
              app.ui.toast.add({
                priority: "warning",
                title: "Unable to update finding",
                message: error
              });
            })
          } else {
            app.ui.toast.add({
              priority: "success",
              title: "Project Findings",
              message: "All project findings from <strong>review</strong> status set to <strong>approved</strong>"
            })

            this.$emit("update-status", response.project);
          }
        }
      )
    }
  }
}
</script>
