<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }" style="min-height: 300px;">
    <div class="row">
      <template v-if="reportData.length > 0">
        <div 
          v-for="(reportCategory, i) in reportData"
          :key="i"
          class="col-md-6 pb-3"
        >
          <div class="stats-tile">
            <div class="tile-header">
              <h3 class="tile-title"><span>{{ reportCategory.heading }}</span> <strong>{{ findings.length }}</strong></h3>
            </div>

            <ul class="stats-list" style="max-height: 250px; overflow: auto;">
              <li v-for="(value, key) in reportCategory.data">
                <span>{{ reportItemName(key) }}</span> <strong> {{ value }}</strong>
              </li>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="!isSpinnerVisible" class="col-sm-12 grid-info-no-records">Selected project has no related findings!</div> 
      </template>
    </div>

    <div class="spinner"></div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import helpers from "../../helpers.js";

export default {
  components: {
    vSelect
  },

  props: {
    projectId: String,
    projectReportTemplate: String
  },

  data: function () {
    return {
      findings: [],
      reportData: [],
      defaultReportTemplate: {
        'Inspection Type': '[findingType][name]',
        'Species': '[component][physicalAsset][details][Species]',
        'Manufacturer': '[component][physicalAsset][details][Manufacturer]',
        'Manufactured Year': '[component][physicalAsset][details][Year]'
      },
      reportTemplate: {},
      isSpinnerVisible: true
    }
  },
  
  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    projectId: function() {
      this.getReportData(this.projectId);
    },

    projectReportTemplate: function() {
      if (this.projectReportTemplate) {
        this.reportTemplate = this.projectReportTemplate;
      }
      else {
        this.reportTemplate = this.defaultReportTemplate;
      }
    }
  },

  created: function() {
    this.reportTemplate = this.defaultReportTemplate;
  },

  methods: {
    getReportData: function(projectId) {
      let fetchParams = {
        filter: {
          projectId: projectId
        }
      };

      this.isSpinnerVisible = true;

      this.reportData = [];

      this.findings = [];

      let currentPage = 1,
          maxPage = 200;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get('report.findings',
          fetchParams,

          (data) => {
            let findings = data.data.findings,
                pageInfo = findings.pageInfo;

            success(findings, pageInfo);
          },

          (error) => {
            $.toast({
              priority: 'danger',
              title: 'Something went wrong!',
              message: 'Unable to fetch report!'
            });
          }
        )
      };

      let getFindings = (cursor) => {
        fetch(
          cursor,

          (findings, pageInfo) => {
            findings.edges.forEach((edge) => this.findings.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getFindings(pageInfo.endCursor))
            }
            else {
              if (this.findings.length > 0) {
                this.setReport()
              }
              else {
                this.isSpinnerVisible = false;
              }
            }
          }
        )
      };

      getFindings(window.btoa(0));
    },

    setReport: function() {
      this.prepareReportData();
    },
    
    prepareReportData: function() {
      let tmpData = [];

      this.findings.forEach((finding) => {
        if (finding.details) {
          finding.details = JSON.parse(finding.details)
        }

        if (finding.component.details) {
          finding.component.details = JSON.parse(finding.component.details)
        }

        if (finding.component.physicalAsset.details) {
          finding.component.physicalAsset.details = JSON.parse(finding.component.physicalAsset.details)
        }

        let findingReportData = [];

        Object.keys(this.reportTemplate).forEach((r) => {
          findingReportData.push(this.h$.getValueByPath(finding, this.reportTemplate[r]))
        });

        tmpData.push(findingReportData);
      })

      for (const [index, [key, value]] of Object.entries(this.reportTemplate).entries()) {
        let reportItem = {
            heading: key,
            data: []
          };

          reportItem.data = tmpData.reduce((acc, item) => {
            acc[item[index]] = (acc[item[index]] || 0) + 1;
            return acc;
          }, {});

        this.reportData.push(reportItem)
      }

      setTimeout(() => {
        this.handlereportTiles();

        this.isSpinnerVisible = false;
      }, 500);
    },

    reportItemName: function(name) {
      return name != 'undefined' ? name : 'Not Defined';
    },

    handlereportTiles: function() {
      document.querySelectorAll('.stats-tile .stats-list').forEach(el => {
        el.classList.toggle("has-scrollbar", el.scrollHeight > el.clientHeight);
      });
    }
  }
}
</script>
