<template>
  <div v-bind:class="{'spinner-is-visible': isSpinnerVisible }">
    <div
      v-if="progress > 0"
      class="progress entity-details-progress"
    >
      <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-bind:class="['entity-details', {'hidden': !isLoaded }]">
      <section>
        <header>
          <div>
            <DamageRating
              :rating="finding.clientRemediatedRating"
            />
          </div>
          <div>
            {{finding.findingType.name}}
          </div>
        </header>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Asset</label>
          <a
            :href="'/physical_assets/' + finding.component.physicalAsset.id"
            tile="Details"
          >{{finding.component.physicalAsset.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Component</label>
          <a :href="'/components/' + finding.component.id" tile="Details">{{finding.component.name}}</a>
        </div>
        <div class="entity-detail form-group">
          <label class="placeholder placeholder-static">Activity date</label>
          {{ h$.formatDate.short(finding.inspection.date) }}
        </div>
        <div
          class="entity-detail form-group"
          v-for="(value, key) in finding.details"
          :key="key"
        >
          <label class='placeholder placeholder-static'>{{key}}</label>
          {{detailFormat(value)}}
        </div>
      </section>

      <section>
        <h3>Photos</h3>
        <photos
          ref="photos"
          :photos="photos"
          :gallery="entityId"
          :user-can-update="userCanUpdate"
          @update-photo="fetchFindingPhotos"
          @progress="uploadProgress"
        ></photos>
      </section>

      <div class="entity-details-actions">
        <div>
          <a
            :href="'/findings/' + entityId"
            title="Details"
            class="btn-expandable btn-expandable-preview btn-expandable-sm"
          >
            <span class="icon" data-icon="fullscreen"></span>
            <span>View</span>
          </a>
        </div>
      </div>

    </div>
    <div class="spinner"></div>
  </div>
</template>

<script>
import EntityDetails from "./entity_details";
import DamageRating from "../damage_rating";
import Photos from "./photos";

import helpers from "../../../helpers.js";

export default {
  extends: EntityDetails,

  components: {
    DamageRating,
    Photos
  },

  props: {
    entityId: String,
    userCanUpdate: Boolean
  },

  data() {
    return {
      finding: {
        findingType: {},
        component: {
          physicalAsset: {
            site: {}
          }
        },
        inspection: {}
      },
      progress: 0,
      photos: [],
      s3UploadUrl: window._s3UploadUrl,
      isLoaded: false,
      isSpinnerVisible: true
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  watch: {
    entityId: function() {
      this.cancelPhotoUpload();

      this.fetchFindingDetails();
    }
  },

  mounted: function() {
    this.fetchFindingDetails();
  },

  computed: {
    showActivity: function() {
      return this.finding.activities && this.finding.activities.length > 0;
    }
  },

  methods: {
    uploadProgress: function(progress) {
      this.progress = progress;
    },

    fetchFindingDetails: function() {
      let vm = this;

      this.isSpinnerVisible = true;

      app.graphql.get(
        "details.finding",

        {
          filter: {
            id: [this.entityId],
            status: ["draft", "review", "approved"]
          }
        },

        (data) => {
          this.isLoaded = true;

          this.finding = data.data.findings.edges[0].node;

          this.finding.details = JSON.parse(this.finding.details);

          // Remove finding description in a case of insufficient rights
          if (!this.userCanUpdate && this.finding.details.hasOwnProperty("Description")) {
            delete this.finding.details.Description;
          }

          this.photos = this.finding.findingPhotos.edges.map((photo) => {return photo.node});

          setTimeout(() => {
            this.isSpinnerVisible = false;
          }, 500);
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding details",
            message : "Something went wrong! Finding cannot be found or doesn't exist!"
          });
        }
      );
    },

    cancelPhotoUpload: function() {
      if ('photos' in this.$refs) {
        if ('cancelPhotoUpload' in this.$refs.photos) {
          this.$refs.photos.cancelPhotoUpload()
        }
      }
    },

    fetchFindingPhotos: function() {
      app.graphql.get(
        "details.findingPhotos",

        {
          filter: {
            findingId: this.entityId
          }
        },

        (data) => {
          this.photos = data.data.findingPhotos.edges.map((photo) => {return photo.node});
        },

        (error) => {
          app.ui.toast.add({
            priority: "danger",
            title : "Finding photos",
            message : "Something went wrong! Finding photos cannot be found!"
          });
        }
      );
    },

    detailFormat: function(value) {
      if (Array.isArray(value)) {
        return value.join(", ");
      }
      
      if (/(\d{4}-\d{2}-\d{2})/.test(value)) {
        return helpers.formatDate.short(value);
      }

      return value;
    }
  }
}

</script>
