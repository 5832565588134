<template>
  <div> 
    <div :class="['entity-photos', {'edit-mode': enableEditMode}]">
      <template v-if="viewMode == 'tiles' || enableEditMode">
        <figure
          v-for="(photo, index) in entityPhotos"
          :key="index"
          :class="{
            'status-delete': photo.status && photo.status == 'delete', 
            'status-edit': photo.status && photo.status == 'edit',
            'status-update': photo.status && photo.status == 'update'
          }"
        >
          <a
            class="entity-photo"
            :href="photo.previewUrl"
            :title="photo.caption"
            :data-id="photo.id"
            :data-index="index"
            :data-gallery="gallery"
            :data-image="photo.imageUrl"
            :data-image-source="photo.sourceUrl"
            @click.prevent.stop="showGallery($event)"
          >
            <img 
              :src="photo.thumbUrl" 
              :title="photo.caption" 
            >
          </a>
          <div class="photo-details">
            <div
              v-if="photo.status && photo.status == 'edit'"
              class="photo-caption-edit"
            > 
              <div>
                <input 
                  type="text" 
                  class="form-control" 
                  :value="photoCaption(photo.caption || photo.image)"
                  :ref="'photo' + index"
                  @keydown.enter="submitEditPhoto(index, $event)"
                  @keydown.escape="cancelEditPhoto(index, $event)"
                >
              </div>
              <div class="photo-actions">
                <a 
                  href="#" 
                  title="Submit"
                  @click="submitEditPhoto(index, $event)"
                >
                  <span class="icon" data-icon="check"></span>
                </a>
                <a 
                  href="#" 
                  title="Cancel"
                  @click="cancelEditPhoto(index, $event)"
                >
                  <span class="icon" data-icon="close"></span>
                </a>
              </div>
            </div>
            <div 
              v-if="!photo.status || photo.status != 'edit'"
              class="photo-caption"
            >
              {{photoCaption(photo.caption || photo.image)}}
            </div>
            <time :datetime="photo.createdAt">{{ h$.formatDate.full(photo.createdAt) }}</time>
            <span class="label-default">{{photoFileExtension(photo)}}</span>
          </div>
          <div 
            v-if="enableEditMode" 
            class="photo-actions"
          >
            <a 
              href="#" 
              title="Edit"
              v-if="!photo.status || photo.status == 'view'"
              @click="editPhoto(index, $event)"
            >
              <span class="icon" data-icon="edit"></span>
            </a>
            <a 
              href="#" 
              title="Delete"
              v-if="!photo.status || photo.status == 'view'"
              @click="deletePhoto(index, $event)"
            >
              <span class="icon" data-icon="delete"></span>
            </a>
          </div>
        </figure>
      </template>
      <template v-else-if="viewMode == 'list'">
        
      </template>
      <template v-else>
        <a
          v-for="(photo, index) in entityPhotos"
          class="entity-photo"
          :key="index"
          :href="photo.previewUrl"
          :title="photo.caption"
          :data-id="photo.id"
          :data-index="index"
          :data-gallery="gallery"
          :data-image="photo.imageUrl"
          :data-image-source="photo.sourceUrl"
          @click.prevent.stop="showGallery($event)"
        >
          <img 
            :src="photo.thumbUrl" 
            :title="photo.caption" 
          >
        </a>
      </template>
    </div>

    <div
      v-if="userCanUpdate"
      :class="['form-control', 'upload', {'focus':$refs.fileUploadPhotos && $refs.fileUploadPhotos.dropActive}, {'is-uploading':isUploading}]"
    >
      <file-upload
        class="btn-expandable btn-expandable-add btn-expandable-sm"
        input-id="fileUploadPhotos"
        :post-action="uploadUrl"
        extensions="gif,jpg,jpeg,png,webp"
        accept="image/png,image/gif,image/jpeg,image/webp"
        :multiple="true"
        :drop="true"
        :size="20000000"
        v-model="uploadPhotos"
        @input-file="inputFile"
        ref="fileUploadPhotos">
          <span class="icon" data-icon="cloud_upload"></span>
          <span>Pick photos</span>
      </file-upload>

      <p>Or Drag Photos Here</p>
    </div>
  </div>
</template>

<script>
import helpers from '../../../helpers.js'

import FileUpload from 'vue-upload-component'

export default {
  components: {
    FileUpload
  },

  props: {
    photos: Array,
    gallery: String,
    userCanUpdate: Boolean,
    entityType: {
      type: String,
      default: "finding"
    },
    enableEditMode: {
      type: Boolean,
      default: false
    },
    viewMode: {
      type: String,
      default: "icons",
      validator: function (value) {
        return [
          "icons",
          "list",
          "tiles"
        ].indexOf(value) !== -1
      }
    }
  },

  data() {
    return {
      isLoaded: false,
      uploadUrl: window._s3UploadUrl,
      entityPhotos: [],
      uploadPhotos: [],
      uploadFileKeys: [],
      uploadSize: 0,
      progress: 0,
      isUploading: false,
      isInFocus: false
    };
  },

  watch: {
    photos: function() {
      this.entityPhotos = this.photos;
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  mounted: function() {
    this.entityPhotos = this.photos;

    this.resetPhotoUpload()
  },

  methods: {
    setPhotoStatus(i, status) {
      const vm = this;

      let photo = vm.entityPhotos[i];

      photo.status = status;

      vm.entityPhotos[i] = photo;
    },

    editPhoto: function(i, event) {
      event.preventDefault();

      const vm = this;

      vm.setPhotoStatus(i, "edit");

      vm.$nextTick(() => {
        vm.$refs["photo" + i][0].focus();
      });
    },

    deletePhoto: function(i, event) {
      event.preventDefault();

      const vm = this;

      let remove = confirm("Are you sure that you want to remove photo?");

      if (remove === true) {
        let photo = vm.entityPhotos[i];

        this.setPhotoStatus(i, "delete");

        app.remote.call({
          type: "DELETE",
          url: "/" + vm.entityType + "_photos/" + photo.id + ".json",
          contentType: false,
          processData: false,
          success: function () {
            vm.entityPhotos.splice(i, 1);

            app.ui.toast.add({
              priority: "success",
              title : "Photo gallery",
              message : "Photo was successfuly removed!"
            });
          }
        });
      };
    },
    
    submitEditPhoto: function(i, event) {
      event.preventDefault();

      const vm = this;

      let photo = vm.entityPhotos[i];
      let photoLabel = vm.$refs["photo" + i][0].value;

      photo.caption = photoLabel;

      vm.setPhotoStatus(i, "update");

      app.remote.call({
        url: '/' + vm.entityType + "_photos/" + photo.id + ".json",
        data : JSON.stringify({
          [vm.entityType + "_photo"]: { caption: photoLabel }
        }),
        type: "PATCH",
        contentType : 'application/json',
        dataType: "json",
        success: function(photo) {
          setTimeout(() => {
            vm.setPhotoStatus(i, "view");

            app.ui.toast.add({
              priority: "success",
              title : "Photo gallery",
              message : "Photo was successfuly renamed!"
            });
          }, 1000);
        },
        error: function() {
          setTimeout(() => {
            vm.setPhotoStatus(i, "view");

            app.ui.toast.add({
              priority: "warning",
              title : "Photo gallery",
              message : "Something went wrong. Photo was not renamed!"
            });
          }, 1000);
        }
      });
    },

    cancelEditPhoto: function(i, event) {
      event.preventDefault();

      const vm = this;

      let photo = vm.entityPhotos[i];

      this.setPhotoStatus(i, "view");
    },

    photoFileExtension: function(photo) {
      let fileName = photo.image || photo.caption;

      return fileName ? fileName.split(".").pop() : "";
    },

    photoCaption: function(caption) {
      return caption ? caption.split(".").slice(0, -1).join(".") || caption : "";
    },

    showGallery: function(e) {
      const photo = e.target.offsetParent;

      const galleryItems = photo.dataset.gallery
        ? [...new Set(Array.from(this.$el.querySelectorAll(`[data-gallery="${photo.dataset.gallery}"]`), (v) => [`${v.title}`, `${v.href}`, `${v.dataset.image}`, `${v.dataset.imageSource}`, `${v.dataset.id}`]))]
        : [`${v.title}`, `${v.href}`, `${v.dataset.image}`, `${v.dataset.imageSource}`, `${v.dataset.id}`];

      vmModal.$refs.modal.show({
        template: 'photoGallery',
        style: {
          class: 'media-gallery-modal',
          verticalyCentered: true
        },
        args: {
          userCanUpdate: this.userCanUpdate,
          entityType: this.entityType,
          gallery: photo.dataset.gallery,
          galleryItems: galleryItems,
          currentItem: photo.dataset.index
        }
      })
    },

    resetPhotoUpload: function() {
      this.isUploading = false;
      this.progress = 0;
      this.uploadFileKeys = [];

      this.$emit("progress", 0);
    },

    cancelPhotoUpload: function() {
      if ('fileUploadPhotos' in this.$refs) {
        const vFileUploadPhotos = this.$refs.fileUploadPhotos;
      
        vFileUploadPhotos.active = false;
        vFileUploadPhotos.clear();
      }

      this.resetPhotoUpload()
    },
    

    inputFile: function(newFile, oldFile) {
      let vm = this;

      if (newFile && !oldFile) {
        // Add new photo
        app.remote.call({
          type: "POST",
          url: "/s3_uploads",
          dataType: "json",
          data: {
            [vm.entityType + "_id"]: vm.gallery,
            file_name: newFile.name
          },
          success: function(response) {
            Object.keys(response).forEach((k) => {
              newFile.data[k] = response[k]
            })

            if (!vm.$refs.fileUploadPhotos.active) {
              vm.$refs.fileUploadPhotos.active = true
            }
          },
          error: function(e, data) {
            app.ui.toast.add({
              priority: "danger",
              title: "Photo upload",
              message: "Photo was not associated!"
            })
          }
        });
      }

      if (newFile && oldFile) {
        // Start upload
        if (newFile.active !== oldFile.active) {
          vm.isUploading = true;
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          vm.$emit("progress", newFile.progress)
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          vm.resetPhotoUpload()

          app.ui.toast.add({
            priority: "danger",
            title: "Photo upload",
            message: "Photo was not uploaded!</br>" + newFile.error
          })
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          var s3Key = newFile.data.key,
              caption = s3Key.split("/").slice(-1)[0],
              gallery = vm.gallery

          app.remote.call({
            url: "/" + vm.entityType + "_photos",
            type: "POST",
            dataType: "json",
            data: {
              s3_key: s3Key,
              [vm.entityType + "_photo"]: {
                [vm.entityType + "_id"]: gallery,
                caption: caption
              }
            },
            success: function(response) {
              vm.$emit("update-photo");

              vm.resetPhotoUpload()

              app.ui.toast.add({
                priority: "success",
                title: "Photo upload",
                message:
                  "Photo <strong>" + caption + "</strong> was added!"
              });
            },
            error: function(e, data) {
              app.ui.toast.add({
                priority: "danger",
                title: "Photo upload",
                message: "Photo was not associated!"
              });
            }
          });
        }
      }
    }
  }
};
</script>
