<template>
  <div class="client-dashboard">
    <div class="row pb-3">
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <v-select
              placeholder="Select project"
              ref="projects"
              :options="projects"
              :clearable="false"
              label="name"
              @update:modelValue="setProject"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <high-risk-sites
          ref="projectHighRiskSites"
          :policy-update="true"
          :project-id="projectId"
          :show-search-field="false"
          :load-on-init="false"
          :show-grid-head="false"
          :show-grid-pagination="false"
          :grid-page-size="200"
        ></high-risk-sites>
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-sm-12">
            <projects-statistics
              ref="projectOverview"
              :project-id="projectId"
              :project-report-template="projectReportTemplate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";

import HighRiskSites from "./high_risk_sites";
import ProjectsStatistics from "./projects_statistics";

export default {
  components: {
    vSelect,
    HighRiskSites,
    ProjectsStatistics
  },

  props: {
    clientCompanyId: String
  },

  data: function () {
    return {
      projects: [],
      projectId: null,
      isSpinnerVisible: true
    }
  },

  mounted: function() {
    this.fetchProjects();
  },

  methods: {
    fetchProjects: function () {
      let fetchParams = {
        filter: {
          clientCompanyId: this.clientCompanyId
        },
        orderBy: {
          field: 'NAME',
          direction: 'DESC'
        }
      };

      this.projects = [];

      let currentPage = 1,
          maxPage = 100;

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get('select.projects',
          fetchParams,

          (data) => {
            let projects = data.data.projects,
                pageInfo = projects.pageInfo;

            success(projects, pageInfo);
          },

          (error) => {
            $.toast({
              priority: 'danger',
              title: 'Something went wrong!',
              message: 'Unable to fetch projects!'
            });
          }
        )
      };

      let getProjects = (cursor) => {
        fetch(
          cursor,

          (projects, pageInfo) => {
            projects.edges.forEach((edge) => this.projects.push(edge.node))

            if (pageInfo.hasNextPage && pageInfo.endCursor && currentPage++ < maxPage) {
              Promise.resolve(getProjects(pageInfo.endCursor))
            }
            else {
              // set current project
              if (this.projects.length > 0) {
                const currentProject = this.projects[0];
                
                this.$refs.projects.updateValue({
                  id: currentProject.id,
                  name: currentProject.name,
                  description: currentProject.description
                });
              }
            }
          }
        )
      };

      getProjects(window.btoa(0));
    },

    getReportTemplate: function(template) {
      if (template) {
        const regex = /<!--(.*?)-->/g;
        
        const matches = [...template.matchAll(regex)];

        const contents = matches.map(match => match[1]);

        if (contents.length > 0) {
          try {
            return JSON.parse(contents[0]).reportTemplate;
          } catch (e) {
            return this.projectReportTemplate
          }
        }
      }
      else {
        return null
      }
    },

    setProject: function(opt) {    
      this.projectReportTemplate = this.getReportTemplate(opt.description);

      this.projectId = opt.id;
    },

    refresh: function() {

    }
  }
}
</script>
