<template>
  <div 
    v-if="!isHidden && isShown" 
    :class="['alert', priorityClass, 'alert-dismissible toast fade', {'in': isVisible}]" 
    role="alert" 
    data-dismiss="alert"
  >
    <strong class="title" v-html="title"></strong><span class="message" v-html="message"></span>
  </div>
</template>

<script>
export default {
  props: {
    userCanUpdate: Boolean,
    id: String,
    title: String,
    message: String,
    priority: String,
    isShown: Boolean,
    isHidden: Boolean
  },

  data() {
    return {
      isVisible: false,
      isVisible: false,
      timing: {
        'info': 4000,
        'success': 4000,
        'danger': 8000, 
        'warning': 8000
      }
    }
  },
  
  mounted: function() {
    this.init();
  },

  computed: {
    priorityClass: function() {
      return 'alert-' + this.priority
    }
  },

  methods: {
    init: function() {
      window.clearTimeout(showToast)

      let showToast = setTimeout(() => {
        this.show()
      }, 100);
    },

    show: function() {
      this.$emit('shown', this.id)
      
      window.clearTimeout(fadeInToast)
      window.clearTimeout(fadeOutToast)

      let fadeInToast = setTimeout(() => {
        this.isVisible = true
      }, 100)

      let fadeOutToast = setTimeout(() => {
        this.hide()
      }, this.timing[this.priority])
    },
    
    hide: function() {
      this.isVisible = false

      window.clearTimeout(hideToast)

      let hideToast = setTimeout(() => {
        this.$emit('hidden', this.id)
      }, 1000)
    }
  }
}
</script>
